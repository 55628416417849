import "./introduction.css";

export default function Introduction () {
    return (
        <div className="introduction-container">
            <div className="content-container">
                <h1>Teaching Philosophy</h1>
                <p>As an educator, I am committed to meeting each student holistically, recognizing their unique backgrounds, experiences, and abilities. I believe that every individual brings valuable perspectives and strengths to the learning environment, and it is my responsibility to create a supportive space where each student feels valued and respected.</p>
                <p>I recognize the importance of meeting students where they are at today, both academically and emotionally. This requires a deep understanding of each student’s individual needs, interests, and aspirations. By taking the time to build authentic relationships with my students, I am better able to tailor my instruction to meet their diverse learning styles and preferences.</p>
                <p>In my classroom, differentiation is not just a strategy, but a guiding principle. I am committed to providing multiple pathways to learning, ensuring that every student has the opportunity to succeed. Whether through small group instruction, project-based learning, or individualized support, I strive to create a learning environment that honors the unique strengths and challenges of each student.</p>
                <p>Above all, I view myself as a facilitator of growth and development. My role as an educator is not just to impart knowledge, but to inspire a love of learning and empower students to reach their full potential. By setting high expectations and providing the necessary support, I aim to help students set and achieve their goals, both inside and outside the classroom.</p>
                <div className="content-btns">
                    <a className="btn" href="/media/lesson_plan.pdf" target="__blank" type="application/pdf">Lesson Plan Example</a>
                    <a className="btn" href="/media/resume.pdf" target="__blank" type="application/pdf">Resume</a>
                    <a className="btn" href="/media/Letters-of-rec.pdf" target="__blank" type="application/pdf">Letters of Recommendation</a>
                </div>
            </div>
            <div className="img-container">
                <img src="/media/bridget_prof.png" alt="Bridget Poland" />
            </div>
        </div>
    )
}