import "./about.css";

export default function About() {
    return (
        <div className="section-container">
            <div className="about-section">
                <h4>About Me</h4>
                <p>Hello! I’m Bridget Poland, a committed educator with a passion for lifelong learning and fostering inclusive environments. With 4 years of experience in education and a deep commitment to meeting the diverse needs of my students, I bring a wealth of knowledge and enthusiasm to the classroom.</p>
                <p>Beyond teaching, I am also passionate about travel. Exploring new cultures and perspectives has been an integral part of my own learning journey, and I am dedicated to inspiring others to do the same. I believe that travel not only expands our perspectives but also fosters empathy, curiosity, and a deeper understanding of the world around us.</p>
                <p>I am excited to continue growing and learning as an educator, and I am grateful for the opportunity to make a positive impact in the lives of my students. Thank you for taking the time to learn more about me!</p>
            </div>
            <div className="teaching-goal-section">
                <h4>Teaching Goal</h4>
                <p>To create an inclusive and engaging learning environment where every student feels valued, respected, and supported, fostering a sense of belonging and empowering them to reach their full potential.</p>
            </div>
        </div>
    )
}