import "./footer.css";

export default function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-content">
                <h4>Contact Me</h4>
                <p><strong>Phone:</strong> {process.env.REACT_APP_CURRENT_PHONE_NUMBER}</p>
                <p><strong>Email:</strong> {process.env.REACT_APP_CURRENT_EMAIL_ADDRESS}</p>
            </div>
        </div>
    )
}