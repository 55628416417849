import "./header.css";

export default function Header() {
    return (
        <div className="header-container">
            <div className="content">
                <h4>Bridget Poland's Portfolio</h4>
            </div>
        </div>
    )
}